.home-card {
  background-color: #ffffff;
  border-radius: 18px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
