.login-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-bg {
  width: 100%;
  height: 100vh;
  background: url("../../../public/img/background/bg-login-back.svg") no-repeat,
    linear-gradient(180deg, #ffffff 0%, #94c7e4 100%);
  background-position: left bottom;
}

.login-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-card {
  width: 100%;
  padding: 24px;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(83, 83, 83, 0.31);
}

.login-form-col {
  display: flex;
  flex-direction: column;
}
