.desk-card {
  height: 130px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.desk-card-v2 {
  height: 160px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.desk-card-v2-title {
  height: 60px;
  width: 100%;
  background-color: #cccccc;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.desk-card-v2-body {
  height: calc(160px - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-title button {
  width: 100px;
  position: absolute;
  top: 8px;
  right: 16px;
  font-size: 14px;
  height: 34px;
}