body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fw-semi-bold {
  font-weight: 600;
}

.fw-bold {
  font-weight: 700;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-25 {
  font-size: 25px;
}

.fs-30 {
  font-size: 30px;
}

.fs-40 {
  font-size: 40px;
}

.fs-60 {
  font-size: 60px;
}

.cl-white {
  color: #ffffff;
}

.cl-grey {
  color: grey;
}

.cl-green {
  color: #00bd84;
}

.txt-underline {
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

.m-0 {
  margin: 0;
}

.align-center {
  align-items: center;
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.large-container {
  padding: 20px;
  min-height: calc(100vh - 80px);
  width: 100%;
  background-color: #ebebeb;
}

.large-inner-container {
  min-height: calc(100vh - 152px);
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(170, 170, 170, 0.64);
  border-radius: 16px;
  padding: 24px 12px;
}

.dropdown-control {
  height: 40px;
  width: 100%;
  color: #ffffff !important;
  font-weight: bold !important;
  border: 1px solid #00bd84 !important;
  border-radius: 50px !important;
  background-color: #00bd84 !important;
  transition: all 0.5s !important;
}

.dropdown-menu-control {
  width: 100% !important;
}

.dropdown-item-control:active {
  background-color: #00bd84 !important;
}

.mt-01 {
  margin-top: 1px !important;
}
.ml-04 {
  margin-left: 4px !important;
}