.button-control {
  height: 40px;
  width: 100%;
  color: #ffffff !important;
  font-weight: bold !important;
  border: 1px solid #00bd84 !important;
  border-radius: 50px !important;
  background-color: #00bd84 !important;
}

.button-control-h-default {
  height: 120px;
  font-size: 48px !important;
  width: 80%;
  color: #ffffff !important;
  font-weight: bold !important;
  border: 1px solid #00bd84 !important;
  border-radius: 16px !important;
  background-color: #00bd84 !important;
}

.button-control-h-100 {
  height: 120px;
  font-size: 44px !important;
  width: 80%;
  color: #ffffff !important;
  font-weight: bold !important;
  border: 1px solid #00bd84 !important;
  border-radius: 16px !important;
  background-color: #00bd84 !important;
}

.button-control-h-80 {
  height: 80px;
  font-size: 40px !important;
  width: 90%;
  color: #ffffff !important;
  font-weight: bold !important;
  border: 1px solid #00bd84 !important;
  border-radius: 16px !important;
  background-color: #00bd84 !important;
}

@media (max-width: 576px) { 
  .button-control-h-default {
    height: 100px;
    font-size: 32px !important;
    width: 100%;
  }
  .button-control-h-100 {
    height: 80px;
    font-size: 28px !important;
    width: 100%;
  }
  .button-control-h-80 {
    height: 80px;
    font-size: 28px !important;
    width: 100%;
  }
}
