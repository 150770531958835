.patient-bg {
  padding: 24px;
  width: 100%;
  min-height: 100vh;
  background: url("../../../public/img/background/bg-login-back.svg") no-repeat
      left bottom,
    linear-gradient(180deg, #ffffff 0%, #94c7e4 100%);
  display: flex;
  flex-direction: column;
}

.patient-header {
  margin-top: 24px;
}

.patient-container {
  width: 100%;
  flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.settings {
  position: absolute;
  top: 18px;
  right: 32px;
}
.settings .btn-group {
  display: flex;
  gap: 4px;
}
.settings .btn-group .btn {
  width: 40px;
}

.button-control-h-default, .button-control-h-80, .button-control-h-100 {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}