.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background-color: #09075f;
  padding: 0 8px;
}

.header-profile-circle {
  background-color: #ffffff;
  border-radius: 50px;
  height: 40px;
  width: 40px;
  margin-right: 20px;
}

.header-dropdown {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  margin-left: 20px !important;
}

.header-dropdown::after {
  display: none !important;
}
